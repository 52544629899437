import { coerceCssPixelValue } from '@florencecard-lib/coercion';
import { useCallback, useEffect, useState } from 'react';

type Nullable<T> = T | null;

export function useIconElem() {
  const [elem, setElem] = useState<Nullable<HTMLElement>>(null);
  const ref = useCallback((elem: Nullable<HTMLElement>) => {
    setElem(elem);
  }, []);

  return [elem, ref] as const;
}

export function useIconSize(elem: Nullable<HTMLElement>, size: string | number) {
  useEffect(() => {
    if (elem == null) {
      return;
    }

    elem.style.width = coerceCssPixelValue(size);
    elem.style.height = coerceCssPixelValue(size);
    elem.querySelectorAll('svg')?.forEach((svg) => {
      svg.style.width = coerceCssPixelValue(size);
      svg.style.height = coerceCssPixelValue(size);
    });
  }, [elem, size]);
}
