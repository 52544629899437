import { css } from '@emotion/react';
import type { As, WithCustomElement } from '@florencecard-components/as';
import { Box, BoxProps } from '@florencecard-components/box';
import classnames from 'classnames';
import React, { memo, useMemo } from 'react';
import { getIconUrl, useSvgIcon } from './icon-registry';
import { useIconElem, useIconSize } from './icon-style';

const styles = css`
  > svg {
    display: inline-block;
    width: inherit;
    height: inherit;
  }

  > img {
    display: inline-block;
    width: inherit;
    height: inherit;
  }
`;

export type IconProps<Element extends As> = WithCustomElement<
  Element,
  Omit<BoxProps<Element>, 'name' | 'size' | 'children'> & {
    name: string;
    size?: number | string;
    children?: never;
  }
>;

function _Icon<Element extends As = 'i'>({
  as = 'i',
  name,
  size = 24,
  color = 'currentColor',
  role = 'img',
  display = 'inline-flex',
  alignItems = 'center',
  justifyContent = 'center',
  overflow = 'hidden',
  className,
  ...props
}: IconProps<Element>) {
  const iconUrl = useMemo(() => getIconUrl(name), [name]);
  const [elem, ref] = useIconElem();
  const [svg, error] = useSvgIcon(name);

  useIconSize(elem, size);

  return (
    <Box
      as={as}
      ref={ref}
      role={role}
      dangerouslySetInnerHTML={svg != null ? { __html: svg } : undefined}
      color={color}
      display={display}
      alignItems={alignItems}
      justifyContent={justifyContent}
      overflow={overflow}
      css={styles}
      className={classnames('Icon', className)}
      {...props}
    >
      {error ? <img alt="" src={iconUrl} width={size} height={size} /> : null}
    </Box>
  );
}

export const Icon = memo(_Icon);
