import { useEffect, useMemo, useState } from 'react';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { filter, finalize, map, share, switchMap, tap } from 'rxjs/operators';

const BASE_URL = 'https://assets.florencecard.me/icons';
const iconSaved = new Map<string, string>();
const iconAjax = new Map<string, Observable<string>>();

export function getIconUrl(iconName: string) {
  return `${BASE_URL}/${iconName}.svg`;
}

function getIcon(iconName: string) {
  const filepath = getIconUrl(iconName);

  if (iconSaved.has(iconName)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return of(iconSaved.get(iconName)!);
  } else if (iconAjax.has(iconName)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return iconAjax.get(iconName)!;
  }

  const request = ajax({
    method: 'GET',
    url: filepath,
    responseType: 'text',
  }).pipe(
    map((data) => data.response as string),
    tap((iconData) => {
      iconSaved.set(iconName, iconData);
    }),
    finalize(() => {
      iconAjax.delete(iconName);
    }),
    share(),
  );

  iconAjax.set(iconName, request);

  return request;
}

const isIconName = (iconName: string | undefined): iconName is string => iconName !== undefined;

export function useSvgIcon(iconName: string) {
  const [svg, setSvg] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const iconName$ = useMemo(() => new BehaviorSubject<string | undefined>(undefined), []);

  useEffect(() => {
    iconName$.next(iconName);
  }, [iconName$, iconName]);

  useEffect(() => {
    const subscription = iconName$
      .pipe(filter(isIconName), switchMap(getIcon))
      .subscribe(setSvg, () => setError(true));

    return () => {
      iconName$.complete();
      subscription.unsubscribe();
    };
  }, [iconName$]);

  return [svg, error] as const;
}
